export default {
    signin: 'Giriş Yap',
    signinNow: 'Hemen Giriş Yap',
    name: 'Ad',
    surname: 'Soyad',
    email: 'E-posta',
    password: 'Şifre',
    confirmPassword: 'Şifreyi Doğrula',
    forgotPassword: 'Şifremi Unuttum',
    forgotPasswordUpper: 'ŞİFREMİ UNUTTUM',
    signup: 'Üye Ol',
    userSignIn: 'Üye Girişi',
    userSignInUpper: 'ÜYE GİRİŞİ',
    loginWithMail: 'E-postanızla giriş yapabilirsiniz.',
    userSignUp: 'HEMEN ÜYE OL',
    signupNow: 'Hemen Üye Ol',
    city: 'Şehir',
    region: 'Bölge',
    postalCode: 'Posta Kodu',
    country: 'Ülke',
    phoneNumber: 'Telefon Numarası',
    save: 'Kaydet',
    saveChanges: 'Değişiklikleri Kaydet',
    gender: 'Cinsiyet',
    cancel: 'Vazgeç',
    delete: 'SİL',
    choose: 'Seçiniz',
    birthday: 'Doğum Tarihi',
    personalInfo: 'Kişisel Bilgileri',
    contactInfo: 'İletişim Bilgileri',
    phone: 'Telefon',
    campaignDetails: 'Kampanya Detayları',
    minAmount: 'Minimum Tutar',
    startDate: 'Başlangıç Tarihi',
    campaignStartDate: 'Kampanya Başlangıç Tarihi',
    addCreditCard: 'Kredi Karti Ekle',
    state: 'İlçe',
    currentPassword: 'Mevcut Şifre',
    newPassword: 'Yeni Şifre',
    newPasswordAgain: 'Yeni Şifre (Tekrar)',
    updatePassword: 'Şifreyi Güncelle',
    account: 'Hesap',
    discount: 'İndirim',
    editAddress: 'Adres Düzenle',
    addressTitle: 'Adres Başlığı',
    changePasswordMessage:
        'Hesabınıza eriştiğiniz parolanızı değiştirmek isterseniz lütfen aşağıdaki bilgileri doldurun.',
    notMember: 'Hala üye değil misin?',
    rememberMe: 'Beni Hatırla',
    cartTitle: 'Sepetim',
    cartNoItem: 'Sepetinizde ürün bulunmuyor',
    sortBy: 'Sırala',
    more: 'En Çok',
    less: 'En az',
    searchInCategories: 'Kategorilerde Ara',
    noMoreProduct: 'ürünün tamamını görüntülediniz.',
    cardNumber: 'Kart numarası',
    alreadyMember: 'Zaten üye misin?',
    allowAgreement: "Kullanıcı sözleşmesi'ni okudum ve kabul ediyorum.",
    allowPromotionSms: 'SMS',
    allowPromotionMail: 'E-posta',
    allowPromotionMessage:
        'SMS yoluyla Ticari Elektronik İleti gönderilmesine, anket ve geri bilgilendirme yapılmasına ve bu kapsamda kişisel verilerimin işlenmesine',
    allowPromotionEMail:
        'E-Posta yoluyla Ticari Elektronik İleti gönderilmesine, anket ve geri bilgilendirme yapılmasına ve bu kapsamda kişisel verilerimin işlenmesine',
    clearConsent: 'Açık Rıza',
    clearConsentText: 'veriyorum',
    clearConsentText2: 'veriyorum',
    addToBasket: 'Sepete Ekle',
    estimatedDelivery: 'Tahmini Teslimat',
    details: 'Detaylar',
    returnPolicy: 'İade Politikası',
    productColor: 'Renk',
    productSize: 'Beden',
    productSizeTable: 'Beden Tablosu',
    productInfoTableCategory: 'Kategori',
    productInfoTableStyle: 'Stil',
    productInfoTableFabric: 'Kumaş',
    productInfoTableComposition: 'Kumaş Karışımı',
    productInfoTableLenght: 'Boy',
    productInfoTableLocation: 'Yeri',
    productInfoTableBrand: 'Marka',
    productNotifyTitle: 'Talebiniz Alınmıştır.',
    productNotifyText:
        'Ürün stoğa geldiğinde kayıtlı mail adresinize e-posta gönderilecektir.',
    productNotifyMe: 'Gelince Bildir',
    favNotifyMe: 'Gelince Bildir',
    productNotifyDescription:
        'Ürün stoğa geldiğinde kayıtlı mail adresinize e-posta gönderilecektir.',
    turnHomepage: 'ANASAYFAYA DÖN',
    notFoundReport: 'SORUN BİLDİR',
    pageNotFoundTitle: 'ULAŞMAYA ÇALIŞTIĞINIZ SAYFA MEVCUT DEĞİL.',
    pageNotFoundText:
        'Daha sonra tekrar deneyebilir, menüyü kullanarak herhangi bir sayfaya geçebilir ya da sorun hakkında bizimle iletişime geçebilirsiniz.',
    featuredCategories: 'ÖNE ÇIKAN KATEGORİLER',
    featuredProducts: 'ÖNE ÇIKAN ÜRÜNLER',
    trendsofWeek: 'HAFTANIN TRENDLERİ',
    headerNoticeHelp: 'YARDIM',
    headerNoticeLng: 'English',
    homepageNotification: " TÜM TÜRKİYE'YE ",
    homepageFree: 'ÜCRETSİZ',
    homepageShip: 'KARGO',
    mobileMenuHomePage: 'Anasayfa',
    mobileMenuSearch: 'Arama',
    mobileMenuFavorites: 'Favorilerim',
    mobileMenuAccount: 'Hesabım',
    mobileMenuMenu: 'Menü',
    mobileMenuCart: 'Sepetim',
    searchModalTitle: 'SON ARAMA',
    footerKeiKei: 'KeiKei',
    footerAboutUs: 'Biz kimiz',
    footerContact: 'İletişim',
    footerFaq: 'Sıkça Sorulan Sorular',
    footerExplore: 'Keşfet',
    footerCampaigns: 'Kampanyalar',
    footerDiscount: 'İndirim',
    footerAllCategories: 'Tüm Kategoriler',
    footerSocialMedia: 'Takip Et',
    footerFacebook: 'Facebook',
    footerInstagram: 'Instagram',
    footerYoutube: 'Youtube',
    footerHelp: 'Yardım',
    footerCookie: 'Çerez Politikası',
    footerTermsConditions: 'Koşullar ve Şartlar',
    footerPrivacyPolicy: 'KVKK Gizlilik Politikası',
    footerChangeReturn: 'Teslimat ve İade',
    footerDeliveryPayment: 'Teslimat ve Ödeme',
    footerNewseller: 'E-Bülten',
    footerJoinUs:
        'E-bültenimize katılın, İlk alış-verişte %10 indirim fırsatını yakalayın!',
    footerDownloadApp: 'Uygulamamızı İndirin',
    footerAllRightsReserved: '© KeiKei tüm hakları saklıdır.',
    footerSecureShopping: 'İNTERNETTE GÜVENLİ ALIŞVERİŞ',
    footerRightsandAssignments: 'Haklar ve Sözleşmeler',
    productName: 'Sarı Kısa Elbise',
    productSizeTitle: 'Beden',
    quantity: 'Adet',
    productAmountTitle: 'Adet',
    productAmount: '1',
    productPrice: '290 TL',
    currency: 'TL',
    goToCart: 'SEPETE GİT',
    buyNow: 'HEMEN AL',
    searchDesktopPlaceholder: 'Sitede ve ürünlerde ara',
    searchMobilePlaceholder: 'Marka, ürün veya kategori ara',
    discountTextCart: 'Sepette',
    discountContainerText: 'İndirim',
    discountAmount: '50%',
    couponCode: 'Kupon kodu',
    hasDiscountCouponCode: 'İndirim Kuponlarım',
    myAccount: 'Hesabım',
    expiryDate: 'Bitiş Tarihi',
    addNewCard: 'Yeni Kart Ekle',
    cardHolderName: 'Kart Sahibi Adı',
    addNewAddress: 'Yeni Adres Ekle',
    myAdress: 'Adreslerim',
    address: 'Adres',
    myCreditCards: 'Kayıtlı Kartlarım',
    order: 'Sipariş',
    totalProducts: 'Toplam Ürün',
    pickSize: 'Beden Seçiniz',
    outOfStock: 'Stokta Yok',
    addToCart: 'Sepete Ekle',
    searchInFavorites: 'Favorilerimde Ara',
    favorites: 'Favorilerim',
    detail: 'Detay',
    cancelOrder: 'Siparişi İptal Et',
    refundOrder: 'Siparişi İade Et',
    total: 'Toplam',
    paymentDetails: 'Ödeme Bilgileri',
    deliveryAddress: 'TESLİMAT ADRESİ',
    sameTheBill: 'Fatura Adresiyle Aynı',
    updateDeliveryAddress: 'Teslimat Adresini Güncelle',
    easyReturn: 'KOLAY İADE',
    seeDeliveryAddress: 'Teslimat Adresini Gör',
    FAQTitle: 'Sıkça Sorulan Sorular',
    FAQShopping: 'ALIŞVERİŞ - SİPARİŞ',
    FAQMembership: 'ÜYELİK',
    FAQShipping: 'KARGO VE TESLİMAT',
    FAQRebate: 'İADE VE DEĞİŞİM',
    headerProduct: 'Ürün',
    noSearchResult: 'Aradığınız kriterde ürün bulunmamaktadır.',
    searchAgain: 'Tekrar Ara',
    aboutUs: 'Hakkımızda',
    aboutKeiKei: 'KeiKei HAKKINDA',
    aboutUsText:
        "KeiKei, kadınların en son moda trendlerine uygun, şık ve özgün kıyafetlere kolayca ulaşabileceği, Türkiye'nin önde gelen markalarından biridir. En son trendleri takip ederek sürekli güncellenen koleksiyonları ve makul fiyatları ile her kadının tarzına uygun seçenekler sunar. KeiKei, internet üzerinden yaptığı satışlarla, müşterilerine kolay ve hızlı bir alışveriş deneyimi sunar. Sadece birkaç tıklama ile arzuladığınız kıyafetleri, ortalama 2 gün içinde tüm Türkiye'ye teslim eder. Misyonumuz, her Türk kadınının moda tutkusunu keşfetmesine ve tarzına uygun kıyafetleri KeiKei ile keşfetmesine olanak sağlamaktır. KeiKei olarak, kadınların kendilerini ifade edebilecekleri, kendilerine özgü tarzlarını yansıtacakları bir moda dünyası yaratmak için çalışıyoruz.",
    aboutUsMission:
        'Misyonumuz; her Türk kadınının ‘Şimdi Moda KeiKei’ demesidir.',
    cookiePolicy: 'Çerez Politikası',
    productMetaText2:
        "Yeni sezon kadın elbise modelleri keikei.com ile kapınızda. Türkiye'nin her yerine ücretsiz kargo seçeneği ile yolluyoruz. Son trendler, moda renklerde göz alıcı modellerden biri sizin için.",
    productMetaText3:
        "Yeni sezon elbise modelleri keikei.com ile kapınızda. Son moda abiyeleri ve elbiseleri Türkiye'nin her yerine ücretsiz kargo seçeneği ile yolluyoruz.",
    cookiePolicyText1:
        ' “Çerezler”, internet sitemizi ziyaret ettiğinizde, internet tarayıcınız tarafından yüklenen ve bilgisayarınız veya cep telefonunuz veya tabletinizde saklanan küçük bilgi parçacıklarını içeren metin dosyalarıdır. Tarayıcınız ve sunucu arasında bir bağlantı sağlandığında site, çerezler aracılığıyla sizi tanıyabilecektir.',
    cookiePolicyText2:
        'Çerezlerin kullanılma amacı, internet sitemizi ziyaret eden kullanıcılara kolaylık sağlamaktır. Çerezler aynı zamanda gelecekte sitelerimiz üzerinde gerçekleştireceğiniz faaliyet ve deneyimleri hızlandırmak amacıyla da kullanılabilir. Ayrıca çerezleri, kişilerin sitelerimizi nasıl kullandıklarını anlamamızı sağlayan isimsiz ve toplu istatistiki verileri bir araya getirmemize ve sitelerimizin yapılarını ve içeriklerini geliştirmemize yardımcı olmaları amacıyla kullanmaktayız.',
    cookiePolicyText3: 'Çerezleri aşağıdaki kategorilere göre ayırmaktayız:',
    cookiePolicyText4:
        'İnternet sitesinin işleyebilmesi için temel olarak ihtiyaç duyulan çerezlerdir. Bu çerezler olmadan, sizlere herhangi bir servis hizmeti vermemiz mümkün olmayacaktır.',
    cookiePolicyText5:
        'Bu çerezler internet sitesinin operasyonunu kolaylaştırır ve fonksiyonlarını geliştirir. Örneğin, işbu internet sitesi için seçmiş olduğunuz dili fonksiyonel çerezler bünyesinde tutmaktayız.',
    cookiePolicyText6:
        'İnternet sitesini nasıl kullandığınızı kaydeden çerezlerdir.',
    cookiePolicyText7:
        'Bu çerezler Twitter, Facebook, Google+ gibi üçüncü kişiler tarafından kullanılmaktadır.',
    cookiePolicyText8:
        'Sizlerin ilgi alanları ve davranış şekilleri doğrultusunda internet sitemizin içeriğini düzenliyoruz. Böylelikle size sunabileceğimiz seçenekleri geliştirebileceğimizi düşünüyoruz. Kullanım seçeneklerini ve tarafınızca internet sitesinde en çok kullanılan alanları belirleyebilmek için kullandığımız çerez modeli Google Analytics’tir.',
    cookiePolicyText9:
        'Çerez sayesinde elde edilen internet sitesi kullanım analizleri genelde ABD’de Google’a ait bir sunucuya aktarılır ve saklanır. ABD’de Avrupa Birliği standartları dahilinde kişisel verilerin korunmasına ilişkin herhangi bir veri koruma sisteminin bulunmadığını lütfen unutmayınız. Ayrıca, tarayıcınız tarafından Google Analytics çerçevesinde iletilen IP-adresiniz Google’a ait diğer verilerle birleştirilmediğini de bildirmek isteriz.',
    cookiePolicyText10:
        'İnternet sitemiz çerezleri ayrıca; arama motorlarını, internet sitesini ve KeiKei’nin reklam verdiği internet sitelerini ziyaret ettiğinizde ilginizi çekebileceğini düşündüğü reklamları size sunabilmek için “reklam teknolojisini” devreye sokmak amacıyla kullanabilir. Reklam teknolojisi, size özel reklamlar sunabilmek için internet sitesine ve KeiKei’nin reklam verdiği internet sitelerine yaptığınız önceki ziyaretlerle ilgili bilgileri kullanır. Bu reklamları sunarken, internet sitesinin sizi tanıyabilmesi amacıyla tarayıcınıza benzersiz bir üçüncü taraf çerezi yerleştirilebilir.',
    cookiePolicyText11:
        'Tarafımızca kullanılmakta olan Çerez bilgileri aşağıdaki gibidir;',
    cookiePolicyText12: 'Oturum Çerezleri',
    cookiePolicyText13: 'KeiKei',
    cookiePolicyText14:
        'Kullanıcının oturumunu kontrol etmek için kullanılan çerezledir.',
    cookiePolicyText15:
        'Web sitelerimizi ziyaretiniz esnasında kullanıcı deneyiminizi en üst seviyeye çekmek, tercihlerinize en uygun, kişiselleştirilmiş içerikleri size sunmak, kendi performansımızı takip etmek, web sitemizi ziyaretiniz esnasında güvenliğinizi ve gizliliği sağlamak, kullanıcı adı ve şifre bilgilerinizi saklamak, size ve ilgi alanlarınıza daha uygun reklamları sağlamak dahil ancak bunlarla sınırlı olmamak kaydıyla, farklı birçok amaç için çerezleri kullanıyoruz.',
    cookiePolicyText16: 'Tüm sayfalar',
    cookiePolicyText17: 'Kalıcı',
    cookiePolicyText18: 'Performans Çerezleri',
    cookiePolicyText19:
        ' Google Analytics, ziyaretçilerin web sitelerinde ve uygulamalarında neler yaptığını web sitesi ve uygulama sahiplerinin anlamasına yardımcı olan, Google’ın ücretsiz analiz aracıdır. Ziyaretçilerin kimliklerini tespit etmeden bilgi toplamak ve web sitesi kullanım istatistiklerini Google`a bildirmek için bir dizi çerez kullanabilir. Google Analytics tarafından kullanılan ana çerez ‘_ga’ çerezidir.',
    cookiePolicyText20:
        'Google Analytics, web sitesi kullanım istatistiklerini raporlamaya ek olarak, yukarıda açıklanan reklamcılık çerezlerinden bazılarıyla birlikte Google mülklerinde (Google Arama gibi) ve web genelinde size daha alakalı reklamlar göstermeye yardımcı olmak ve gösterdiğimiz reklamlara ilişkin etkileşimleri ölçmek için de kullanılabilir.',
    cookiePolicyText21:
        'Daha iyi bir kullanıcı deneyimi sunmak ve ilgi alanınıza giren reklamların size ulaşmasını ve ölçümlenmesini sağlamak amacıyla kullanılmaktadır.',
    cookiePolicyText22:
        'Birçok internet tarayıcısı, varsayılan olarak çerezleri otomatik olarak kabul etmeye ayarlıdır. Bu ayarları, çerezleri engelleyecek veya cihazınıza çerez gönderildiğinde uyarı verecek şekilde değiştirebilirsiniz. Fakat bunu yapmanız durumunda, internet sayfasının tüm özelliklerinin tam anlamıyla kullanılamayabileceği konusunda sizleri bilgilendirmek isteriz.',
    cookiePolicyTitle1: 'Ana Çerezler:',
    cookiePolicyTitle2: 'Fonsksiyonler Çerezler:',
    cookiePolicyTitle3: 'Performans Çerezleri:',
    cookiePolicyTitle4: 'Üçüncü Kişi Çerezleri:',
    cookiePolicyTitle5: 'Çerez Adı:',
    cookiePolicyTitle6: 'Sağlayıcı',
    cookiePolicyTitle7: 'Amaç:',
    cookiePolicyTitle8: 'Sayfa:',
    cookiePolicyTitle9: 'Son kullanım:',
    cookiePolicyTitle10: 'Kategori:',
    cookiePolicyTitle11: 'Çerez Yönetimi ve İmhası:',
    termsConditions: 'ŞARTLAR VE KOŞULLAR',
    termsConditionstitle1:
        '1.Kişisel Verilerin İşlenmesi Ve Korunmasına İlişkin Gizlilik Politikası',
    termsConditionstitle2: '2.Fikri Mülkiyet Hakları',
    termsConditionstitle3: '3.Diğer İnternet Sitelerine Linkler',
    termsConditionstitle4: '4.“www.keikei.com”ya linkler',
    termsConditionstitle5: '5.İçerikle İlgili İbra',
    termsConditionstitle6: '6.İş Politikamız',
    termsConditionstitle7: '7.Uygulanacak Hukuk',
    termsConditionstitle8: '8.Hatalar ve yanlışlar',
    termsConditionsText1:
        'İnternet sitemize (www.keikei.com ) hoş geldiniz. Bu Koşullar ve Şartlar, www.keikei.com internet sitemize erişim ve kullanımı için geçerlidir. Bu internet sitesine erişim ve kullanımı ve ayrıca keikei.com’daki ürünlerin satın alınması bu Koşulların ve Şartların tarafınızca okunduğu, anlaşıldığı ve kabul edildiği varsayımına dayanmaktadır. Bu internet sitesi Dolapdere Caddesi Küçük Bayır Sokak No: 119/A 34272 Şişli - İstanbul / Türkiye adresinde mukim KeiKei Tekstil ve Giyim Sanayi Ticaret Anonim Şirketi tarafından yönetilmekte ve muhafaza edilmektedir.',
    termsConditionsText2:
        'Eğer ek bilgiye veya yardıma ihtiyaç duyarsanız, siparişlere, sevkiyata, www.keikei.com sitesinden satın alınmış ürünlerle ilgili geri ödemelere ve iadelere, bir kayıt formuna, www.keikei.com sitesinde sağlanan hizmetlere ilişkin püf noktalarına diğer genel bilgilere ulaşacağınız İletişim Sayfası’na gidiniz.',
    termsConditionsText3:
        'KeiKei bu Koşullar ve Şartlar’ın tamamını veya bir kısmını değiştirebilir veya güncelleyebilir. Koşullar ve Şartlar’daki herhangi bir değişiklik veya güncelleme, söz konusu değişiklikler veya güncellemeler yapılır yapılmaz www.keikei.com internet sitesinin Ana Sayfa’sında kullanıcılarımız için yayınlanacak ve internet sitesinin bu bölümünde yayınlanır yayınlanmaz bağlayıcı olacaktır. Bu nedenle, www.keikei.com’un en son ve güncellenmiş Koşullar ve Şartları yayınını kontrol etmek için internet sitesinin bu kısmını düzenli olarak ziyaret etmeniz gerekmektedir. Eğer www.keikei.com’un tamamını veya bir kısmını kabul etmiyorsanız, lütfen internet sitemizi kullanmayın.',
    termsConditionsText4:
        'İnternet sayfalarının görüntülenmesi, KeiKei ile iletişim, ürün bilgilerinin indirilmesi ve internet sitesinden satın alma işlemi dahil olmak üzere www.keikei.com ‘e erişim ve kullanım münhasıran kişisel amaçlar doğrultusunda gerçekleştirilmektedir ve hiçbir şekilde herhangi bir ticari, şirket, işletme veya profesyonel faaliyet ile bağlantılı kılınmayacaktır. www.keikei.com sitesini ve içeriğini kullanımınızdan yükümlü olacağınızı unutmayın. KeiKei, KeiKei`nin kasıtlı haksız fiiller ve ağır ihmalle ilgili yükümlülüğüne dokunulmaksızın, internet sitesinin ve içeriğinin kullanıcılar tarafından yürürlükteki yasalara ve yönetmeliklere uygun olmayan şekilde kullanılmasından dolayı yükümlü tutulmayacaktır.',
    termsConditionsText5:
        'Bilhassa yanlış ve sahte veya üçüncü kişilerle (söz konusu üçüncü kişilerin onay vermemiş olmamaları durumunda) ilgili bilgilerin veya verilerin iletilmesi ve ayrıca söz konusu verilerin veya bilgilerin uygun olmayan şekilde kullanımı durumunda yükümlü olacaksınız.',
    termsConditionsText6:
        'Kullanıcıların www.keikei.com`ye erişmeleri ve satın alma gerçekleştirmeden ilgili hizmetleri kullanmaları durumunda da geçerli olan Kişisel Verilerin Korunmasına İlişkin Gizlilik Politikası’nı (“Gizlilik Politikası”) okumanızı tavsiye ediyoruz. Gizlilik Politikası, www.keikei.com’ ye internet sitesinin kişisel verilerinizi nasıl ve ne amaçla topladığını anlamanıza yardımcı olacaktır.',
    termsConditionsText7:
        'Bu siteye dahil edilmiş olan işler, görüntüler, resimler, diyaloglar, müzik, sesler, görüntüler (videolar), belgeler, çizimler, şekiller, logolar, menüler, web sayfaları, grafikler, renkler, planlar, araçlar, fontlar, tasarımlar, şemalar, mizanpajlar, yöntemler, işlemler, fonksiyonlar ve yazılım (hep birlikte “İçerik” olarak anılacaklardır) gibi bütün içerik KeiKei`nin malıdır ve ulusal ve uluslararası telif hakkı ve diğer fikri mülkiyet yasaları tarafından korunmaktadır. KeiKei`nin önceden açık ve yazılı onayı olmadan İçeriği tamamen veya kısmen herhangi bir şekilde çoğaltamaz, yayınlayamaz, dağıtamaz, görüntüleyemez, değiştiremez ve bunlardan türeve işler yaratamazsınız.',
    termsConditionsText8:
        'KeiKei`nin, tamamen kendi takdirine kalmış şekilde İçeriğin herhangi bir şekilde çoğaltılmasına, yayınlanmasına, dağıtımına, görüntülenmesine, değiştirilmesine, bunlardan türev işler yaratılmasına veya tamamen veya kısmen kullanılmasına izin verme veya yasaklama konusunda münhasır hakka sahip olacaktır.',
    termsConditionsText9:
        'KeiKei`nin, herhangi bir zamanda, bu Sitede yayınlanan herhangi bir İçeriğin yazarı olduğunu iddia etme ve söz konusu İçeriğin herhangi bir şekilde kullanılmasına, çarpıtılmasına veya başka bir şekilde değiştirilmesine itiraz etme hakkına sahip olacaktır. KeiKei`nin tarafından açıkça ve yazılı olarak izin verilen İçeriğin herhangi bir şekilde çoğaltılması, yayınlanması, dağıtımı, gösterimi, değiştirilmesi, bunlardan türev işler yaratılması işlemlerini sadece yasal amaçlar doğrultusunda ve yürürlükteki bütün yasalara uygun olarak gerçekleştirebilirsiniz.',
    termsConditionsText10:
        'www.keikei.com , KeiKei ile herhangi bir şekilde bağlantılı olmayan başka internet sitelerine linkler içerebilir. Keikei, söz konusu üçüncü kişi internet sitelerinin veya anların içeriklerini kontrol etmemekte veya izlememektedir. Keikei söz konusu sitelerin içeriğinden ve/veya kişisel gizliliğinizle ve o internet sitelerini ziyaret ettiğiniz zaman kişisel verilerinizin işlenmesiyle ilgili olarak, fakat bunlarla sınırlı olmaksızın, benimsemiş oldukları kurallardan dolayı yükümlü tutulmayacaktı. Lütfen www.keikei.com üzerindeki linkler yoluyla bu internet sitelerine erişirken dikkatli olun ve onların şartlarını ve koşullarını ve kişisel gizlilik politikalarını dikkatli okuyun. Koşullar ve Şartlar ile Gizlilik Politikası üçüncü kişilerin internet siteleri için geçerli değildir.',
    termsConditionsText11:
        'Eğer www.keikei.com’nun Ana Sayfasına ve kamunun erişebileceği diğer internet sayfalarına link verme konusuna ilgi duyuyorsanız lütfen Müşteri Destek Ekibimiz ile irtibata geçin. KeiKei, www.keikei.com’a linkleri etkinleştirmek isteyen başvuru sahibinin geçmişte piyasa operatörleri tarafından genel olarak benimsenmemiş veya kabul edilmemiş adil olmayan ticari veya iş uygulamalarını benimsemiş olmaları veya Sağlayıcıya veya onun tedarikçilerine karşı adil olmayan rekabet faaliyetlerinde bulunmuş olması veya KeiKei`nin böyle uygulamaların veya böyle faaliyetlerin gelecekte başvuru sahibi tarafından benimsenebileceğinden çekinmesi durumunda internet sitesine linkler verilmesine itiraz etme hakkına sahiptir. Her halükarda, KeiKei`nin onayı olmadan www.keikei.com’ya yönlendiren derin (derin çerçeveler gibi) konulması veya meta etiketlerin kullanılması yasaktır.',
    termsConditionsText12:
        'KeiKei, internet sitesinin içeriğinin Türkiye dışındaki ülkelerde uygun veya yasal olduğunu garanti etmemektedir. Ne var ki, söz konusu içeriğin bu ülkelerin bazılarında yasadışı veya illegal görülmesi durumunda, lütfen bu internet sitesine erişmeyin ve yine de internet sitesine erişmek isterseniz o zaman www.keikei.com tarafından sağlanan hizmetleri kullanmanızın sizin münhasır ve kişisel sorumluluğunuzda olacağını burada bildiririz. KeiKei ayrıca www.keikei.com içeriğinin doğru olmasını ve yanlış veya güncel olmayan bilgiler içermemesini sağlamak için de önlemler almaktadır. Ne var ki, KeiKei, kendisinin haksız fiili ve ağır ihmali söz konusu olmadıkça ve yasanın gerektirdiği diğer durumlar haricinde, içeriğin doğruluğu ve tamlığı konusunda yükümlü tutulamaz. Dahası, Keikei internet bağlantısından kaynaklanan kesintiler ve hatalar olmadan sürekli çalışacağını garanti edemez. İnternet sitemizin kullanımındaki herhangi bir sorun durumunda, lütfen Müşteri Destek Ekibimize danışın. Bir KeiKei temsilcisi, mümkün olduğunca internet sitesine tekrar erişmenize yardımcı ve destek olmak için hizmetinizde olacaktır.',
    termsConditionsText13:
        'Aynı zamanda, lütfen internet hizmet sağlayıcınızla irtibata geçin veya tarayıcınız dahil olmak üzere internet bağlantısına ve internet içeriğine erişime ilişkin her cihazın doğru şekilde etkinleştirildiğinden emin olun. İnternetin ve web içeriğinin dinamik niteliği www.keikei.com’nin internet sitesinin güncellenmesinden kaynaklanan geçici duraklamalar, kesintiler veya devamsızlık söz konusu olmadan çalışmasına izin vermemektedir. Sağlayıcı, verilerin izinsiz kullanımını veya bunlara erişimi engellemek ve aynı zamanda www.keikei.com kullanıcılarına ilişkin verilerin ve gizli / gizli-olmayan bilgilerin yayılması, tahrifi ve kaybolması risklerini engellemek amacıyla www.keikei.com sitesindeki hizmetleri, verilerin ve elektronik iletişimin doğruluğunu korumak ve söz konusu verilere ve bilgilere izinsiz veya yasadışı erişimi engellemek için uygun teknik ve organizasyon güvenlik önlemlerini almıştır.',
    termsConditionsText14:
        'Sağlayıcı, bir iş politikası benimsemiştir; misyonu ise hizmetleri ve internet sitesi kanalıyla ürünlerini sadece B2C tüketicisine satmaktan ibarettir. B2C Tüketicisi ifadesi kendi ticari, iş veya profesyonel faaliyeti (eğer varsa) dışında www.keikei.com’da hareket eden herhangi bir gerçek kişi, tüzel kişi, diğer toptancılar vs. anlamına gelmektedir.',
    termsConditionsText15:
        'Bu Koşullar ve Şartlar Türkiye Cumhuriyeti yasalarına göre yönetilmektedir.',
    termsConditionsText16:
        'Amacımız internet sitemizde tam, doğru ve güncel bilgi vermektir. Maalesef, herhangi bir internet sitesinin insandan veya teknolojiden kaynaklanan hatalardan tamamen arındırılmasını sağlamak mümkün değildir. Bu internet sitesinde yazım hataları, yanlışlar veya atlamalar olabilir.',
    privacyPolicy:
        'KİŞİSEL VERİLERİN İŞLENMESİ VE KORUNMASINA İLİŞKİN GİZLİLİK POLİTİKASI',
    KVKK: 'KVKK GİZLİLİK POLİTİKASI',
    privacyPolicyTitle1:
        'Kişisel Verileri Toplama Yöntemleri ve Hukuki Sebepleri:',
    privacyPolicyTitle3:
        'Farklı Aşamalarda İşlenebilecek Veri Kategorileri ve Veri Türleri',
    privacyPolicyTitle4: 'İletişim:',
    privacyPolicyTitle5: 'Müşteri İşlem:',
    privacyPolicyTitle6: 'Finans:',
    privacyPolicyTitle7: 'Kişisel Verilerin İşleme Amacı',
    privacyPolicyTitle8: 'Kişisel Verilerin Aktarılması',
    privacyPolicyTitle9:
        '5. Kişisel Verilerinize Yönelik KVKK Kapsamındaki Haklarınız Nelerdir?',

    privacyPolicyText1: 'Değerli Müşterimiz',
    privacyPolicyText2:
        'Kişisel verileriniz 6698 sayılı Kişisel Verilerin Korunması Kanunu uyarınca veri sorumlusu olarak Keikei Tekstil Ve Giyim Sanayi Ticaret Anonim Şirketi (‘’[KEİKEİ]’’ veya “Şirket”) tarafından aşağıda açıklanan şekilde işlenebilecektir.',
    privacyPolicyText3:
        'Şirketimiz, kişisel verileri, Site üzerinden yaptığınız alışverişlerin veya üyelik işlemlerine ilişkin yahut üye olmadan alışveriş için paylaştığınız bilgilerin sistemlerimize kaydedilmesi suretiyle Sabit/mobil internet ve iletişim cihazlarınızın ve/veya mobil uygulamaların muhtelif ortamlarda ve yerde kullanımlarından oluşan/edinilen verilerin sistemlerimize kaydedilmesi suretiyle, Site üzerindeki her türlü üyelik, alışveriş, tahsilat, teslimat ve bunlara yönelik işlem, anket doldurma, kayıt ve davranışsal işlemlerin sistemlerimize kaydedilmesi ve iade süreçlerine ilişkin kayıtların ilgili evrakların kaydedilmesi vasıtaları kapsamında doğrudan müşterinin kendisinin verdiği bilgilerden ve tahsilat araçları vasıtasıyla öğrenilen verilerden elde etmektedir. Kişisel verileriniz; ',
    privacyPolicyText4:
        'Satın aldığınız ürünlerin gönderimi için teslimat adresinizin talep edilmesi, doğru gönderim için isim soy isim bilgilerinizin alınması, üyelik sözleşmesi kapsamında ID ve şifre bilgilerinizin alınması gibi işlemler yönünden sözleşmenin kurulması ve ifası için veri işlemenin gerekli olması,  ',
    privacyPolicyText5:
        'Vergi Usul Kanunu ve diğer mevzuat kapsamında satış yapılan ürünlerin faturalarının tanzimi ile bildirilmesi, Tüketicinin Korunması Hakkında Kanun ve yan mevzuat kapsamında cayma ve diğer haklarınızı etkin kullanabilmeniz için iade yapılacak banka hesap bilgileriniz kanunlarda açıkça öngörülme ve hukuki yükümlülüğün yerine getirilmesi,',
    privacyPolicyText6:
        'Kişisel verileriniz, müşteri kayıtlarının oluşturulması ve takibi, sözleşmenin ifa edilebilmesi, fatura tanzimi ve iletilmesi, satış ve satış sonrası hizmetlerde müşteri menfaatinin korunması, özellikle iade ile ilk ve yeniden ürünlerin kargo vasıtasıyla gönderilmesi, bedel iadesi dahil ürün iade sürecinin yürütülmesi, müşteriye sonraki olası işlemlerinde kolaylık sağlanması, talep ve şikayetlerin takibi, iş sürekliliği ve faaliyetin devamlılığının sağlanması, Ticari İletişim ve Ticari Elektronik İletiler Hakkında Yönetmelik kapsamında izin veren müşterilerimize kampanya – indirim vb. durumlarda müşterinin yararlanabilmesi için haberdar edilmesi, bilgi güvenliği süreçlerinin planlanması, denetim ve icrası, yetkili kişi, kurum ve kuruluşlara bilgi verilmesi,  bilgi teknolojileri alt yapısının oluşturulması ve yönetilmesi amaçlarıyla işlenmektedir.',
    privacyPolicyText7:
        'Reklam ve diğer opsiyonel çerezlerin sizlere daha iyi bir deneyim sunulması ve pazarlama faaliyetleri yürütülmesi için bu ve benzeri işlemler ise açık rıza hukuki sebeplerine dayalı olarak işlemektedir.',
    privacyPolicyText9: 'Ad –Soyad, T.C. Kimlik Numarası, Doğum Tarihi',
    privacyPolicyText10: 'Telefon numarası, E posta adresi, Lokasyon, Ülke,',
    privacyPolicyText11:
        'Alışveriş geçmişi bilgileri, Satın alınan ürün/hizmet bilgileri, Sipariş bilgisi, Üyelik ID- Şifre Bilgisi, Teslimat adresi, Siteyi kullanım bilgileriniz, Çerez kayıtlarınız,',
    privacyPolicyText12:
        'Banka/kredi kartı bilgileri, Fatura adresi ve diğer fatura bilgileri, Banka hesap bilgileri',
    privacyPolicyText13: 'Amaçlar',
    privacyPolicyText14: 'İşleme için Hukuki Temel',
    privacyPolicyText15:
        '· İnternet Sitesi üzerinden gerçekleşecek olan üyelik ve satış süreçlerine ilişkin sözleşmenin akdedilmesi,',
    privacyPolicyText16: '· sipariş edilen ürünün adresinize gönderilmesi,',
    privacyPolicyText17: '· fatura düzenlenmesi,',
    privacyPolicyText18: '· ödeme alınması,',
    privacyPolicyText19:
        '· bizimle iletişime geçtiğiniz takdirde sorun ve şikayetlerinizi çözümlenmesi ve gerektiğinde buna ilişkin olarak tarafımızın sizinle iletişime geçilebilmesi,',
    privacyPolicyText20:
        '· ürün değişim, iade ve transfer taleplerinin inceleyip değerlendirebilmek,',
    privacyPolicyText21:
        '· çağrı merkezi kalite standartları ve sizlere yapılan görüşmelerin hizmet/işlem güvenliğinin sağlayabilmek,',
    privacyPolicyText22:
        '· tüm müşteri ilişkileri süreçlerini daha iyi yönetebilmek, takip edebilmek ve raporlayabilmek, ve',
    privacyPolicyText23: '· sözleşmenin ifası ile ilgili diğer amaçlarla',
    privacyPolicyText24:
        'Sözleşmenin kurulması ve ifası için veri işlemenin gerekli olması hukuki sebebine dayanarak;',
    privacyPolicyText25:
        '· KeiKei’nin ilgili departmanlarının faaliyetleri kapsamında mevzuattan kaynaklanan yükümlülüklerimizi yerine getirmek,',
    privacyPolicyText26:
        '· Yetkili ve görevli kamu kurum ve kuruluşları ile adli makamlara karşı olan bilgi, belge verme ve ilgili sair yükümlülüklerimizi yerine getirmek,',
    privacyPolicyText27:
        '· bilgi güvenliği başta olmak üzere diğer hukuki yükümlüklerimizi yerine getirmek amaçlarıyla',
    privacyPolicyText28:
        '· Hukuki yükümlülüğün yerine getirilmesi hukuki sebebine dayanarak',
    privacyPolicyText29:
        '·KeiKei’nin ilgili departmanlarının faaliyetleri kapsamında sözleşmeden doğacak uyuşmazlıklarda mahkeme, icra dairesi, hakem heyeti gibi resmi kurum ve kuruluşlara karşı her türlü dava, cevap ve itiraz hakkının kullanılması amacıyla ',
    privacyPolicyText30:
        '· Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması hukuki sebebine dayanılarak',
    privacyPolicyText31:
        'Ayrıca, kişisel verilerinizi yukarıda sayılan ve ilgili sair mevzuatta sayılan haller haricinde hiçbir şekilde başka bir ticari amaçla kullanmayacağımızı bilgilerinize sunarız.',
    privacyPolicyText32:
        'KeiKei olarak, kişisel verilerinizi, Site üzerinden yaptığınız alışverişlerin veya üyelik işlemlerine ilişkin paylaştığınız bilgilerin sistemlerimize kaydedilmesi, sabit-mobil nitelikli internet ve iletişim cihazlarınızın kullanımlarından oluşan/edinilen verilerin sistemlerimize kaydedilmesi, İnternet Sitesi üzerindeki her türlü alışveriş, tahsilat, teslimat, işlem, anket doldurma, kayıt ve davranışsal işlemlerin sistemlerimize kaydedilmesi, telefon yoluyla sizinle iletişim kurulması esnasında vermiş olduğunuz bilgilerin sistemlerimize kaydedilmesi gibi otomatik yöntemlerle toplamaktayız.',
    privacyPolicyText33:
        'Kişisel verileriniz, yukarıda belirtilen veri işleme amaçlarını gerçekleştirmek üzere, Şirketimiz ile bağlı olan ortaklarımıza, grup şirketlerimize, tedarikçilerimize, hissedarlarımıza, kanunen yetkili kamu kurumları ve özel kişilere KVKK ve GDPR ile belirlenen işleme şartları ve amaçları çerçevesinde aktarılabilecektir.',
    privacyPolicyText34: 'Bu kapsamda KeiKei olarak;',
    privacyPolicyText35:
        '· Bilişim teknolojileri, pazarlama faaliyetleri, ya da uzmanlık gerektiren danışmanlık vb. hizmetlerini almak, kişisel veri toplama süreçlerinde ürün ve hizmet desteği almak gibi amaçlarla KeiKei’nin yurtiçi ve yurtdışında bulunan iş ortakları ve hizmet sağlayıcıları (çağrı merkezi, cihazlar aracılığıyla kişisel veri toplama hizmeti, pazarlama danışmanlığı, veri tabanı, elektronik ileti aracı hizmet sağlayıcıları, danışmanlık, vb. hizmeti veren firmaları ile) ile paylaşmaktayız;',
    privacyPolicyText36:
        '· Site üzerinden yaptığınız alışverişlerinizin ve diğer her türlü ürün taleplerinizin size gönderilmesi amacıyla kurye ve kargo hizmeti aldığımız iş ortaklarımıza aktarmaktayız;',
    privacyPolicyText37:
        '· Yetkili ve görevli kamu kurum ve kuruluşları ile adli makamlara karşı olan bilgi, belge verme ve ilgili sair yükümlülüklerimizi yerine getirmek ve dava ve cevap hakları gibi yasal haklarımızı kullanabilmek amacıyla bizden istenen bilgileri anılan bu kurum, kuruluş ve makamlara aktarmaktayız;',
    privacyPolicyText38:
        '· Ödemenizi kredi kartı ile yapmanız halinde kredi kartı bilgilerinizi, KeiKei tarafından kaydedilmeksizin ilgili banka, elektronik ödeme kuruluşu vb. hizmeti sağlayan üçüncü kişilere aktarmaktayız.',
    privacyPolicyText39:
        "· Size e-posta ya da SMS ile kampanya bilgilendirmeleri yapılmasını kabul etmeniz halinde: KeiKei tarafından size özel satış ve pazarlama faaliyetlerinin planlanması, ürün ve hizmetlerin sizlerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek sizlere önerilmesi, müşterilerin alışveriş geçmişlerinizin değerlendirilerek müşterilere kampanya ve teklifler sunulması, bu kapsamda müşteri ve potansiyel müşterinin iletişim bilgilerine ticari elektronik ileti gönderilmesi, Şirketimiz tarafından sunulan ürün ve hizmetlere bağlılığın oluşturulması veya arttırılması gibi amaçlarla ürün ve hizmetlerin kişilerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek önerilmesi, tanıtılması ve gerekli olan aktivitelerin planlanması ve icrası, ürün ve hizmetlerimize ilişkin memnuniyet ve bağlılık araştırmalarının yapılması amaçlarıyla KeiKei'nin yurtiçi ve yurtdışında bulunan iş ortakları ve elektronik ileti aracı hizmet sağlayıcıları ile paylaşılabilmektedir.",
    privacyPolicyText40:
        'KVKK madde 11 uyarınca kişisel verilerin sahibi olan kişilerin hakları aşağıda belirtilen şekildedir;',
    privacyPolicyText41:
        'a) Sahip olduğunuz kişisel verilerin işlenip işlenmediğini öğrenme hakkı,',
    privacyPolicyText42:
        'b) Kişisel verilerinizin işlenme ihtimalinde buna ilişkin bilgi talep etme hakkı,',
    privacyPolicyText43:
        'c) Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme hakkı,',
    privacyPolicyText44:
        'd) Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme hakkı,',
    privacyPolicyText45:
        'e) Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme hakkı,',
    privacyPolicyText46:
        'f) KVKK madde 7’de belirtildiği üzere, KVKK ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme hakkı,',
    privacyPolicyText47:
        'g) (e) ve (f) bentleri uyarınca yapılan işlemlerin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,',
    privacyPolicyText48:
        'h) İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme hakkı,',
    privacyPolicyText49:
        'i) Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme hakkı.',
    privacyPolicyText50:
        'KVKK kapsamındaki haklarınızı yazılı bildiriminiz ile kullanabilirsiniz.',
    privacyPolicyText51:
        'İşbu bildirimlerinizi Şirket merkezimiz olan Dolapdere Cd. Küçükbayır Sk. 119/A Şişli / İstanbul-Türkiye adresine posta yolu ile ulaştırabilirsiniz.',
    privacyPolicyText52:
        'Bildirimlerinizi internet sitemizde bulunan Başvuru Formu aracılığı ile kullanabilirsiniz. Başvuru Formunun linki aşağıdaki gibidir:',
    privacyPolicyText53: 'Başvuru Formu',
    privacyPolicyText54:
        'Bu kapsamda yapacağınız başvurular mümkün olan en kısa zaman diliminde ve en çok otuz (30) gün içerisinde sonuçlandırılacaktır.',
    changeAndReturn: 'TESLİM, DEĞİŞİM, HATALI ÜRÜN KOŞULLARI',
    changeAndReturnTitle: 'TESLİM, DEĞİŞİM, HATALI ÜRÜN KOŞULLARI',
    deliveryAndPayment: 'SİPARİŞ VE ÖDEME',
    deliveryAndPaymentTitle: 'Mesafeli Satış Sözleşmesi',
    distanceSellingPreInfo: 'MESAFELİ SATIŞ ÖN BİLGİLENDİRME FORMU',
    send: 'Gönder',
    forgotMyPassword: 'Şifremi Unuttum',
    resetMyPassword: 'Şifremi Sıfırla',
    passwordRequirement:
        'Şifreniz en az 7 karakter olmalı, harf ve rakam içermelidir.',
    forgotPasswordSendMail:
        'Lütfen e-posta adresinizi girin ve gönderin. Daha sonra size yeni bir şifre oluşturmak için bağlantı içeren bir e-posta göndereceğiz.',
    addressInfo: 'ADRES BİLGİLERİ',
    enterDeliveryAddress: 'Teslimatın yapılmasını istediğiniz adresi giriniz.',
    changeAddress: 'Adres Değiştir',
    billingAddress: 'FATURA ADRESİ',
    deliveryAndBillingAddressSame: 'Faturamı aynı adrese gönder.',
    paymentOptions: 'ÖDEME SEÇENEKLERİ',
    enterCardInfo:
        'Ödemeyi gerçekleştirmek istediğiniz kart bilgilerinizi giriniz.',
    creditCardNumber: 'Kart Numarası',
    validityDate: 'Geçerlilik Tarihi',
    CVV: 'CVV',
    confirmSaveCardInfos: 'Kart bilgilerimin kaydedilmesini onaylıyorum.',
    want3dSecure: '3D Güvenli Ödeme Sistemi ile ödemek istiyorum.',
    installmentOptions: 'TAKSİT SEÇENEKLERİ',
    selectInstallmentOption: 'Kartınıza uygun taksit seçeneğini işaretleyin.',
    installmentNumber: 'Taksit Sayısı',
    monthlyPayment: 'Aylık Ödeme',
    cardInfo: 'KART BİLGİLERİ',
    payWithAnotherCard: 'BAŞKA KART İLE ÖDE',
    completePayment: 'ÖDEMEYİ TAMAMLA',
    add: 'Ekle',
    edit: 'Düzenle',
    goOrdersPage: 'SİPARİŞLERİM SAYFASINA GİT',
    estimatedDeliveryDate: 'Tahmini Teslimat Tarihi',
    color: 'Renk',
    en: 'İngilizce',
    tr: 'Türkçe',
    GO: 'GİT',
    cartStockOut: 'Sepetinizdeki ürün tükendi!',
    contact: 'İLETİŞİM',
    factoryAddressTitle: 'Fabrika',
    headOfficeAddressTitle: 'Merkez Ofis',
    contactUs: 'Bize Ulaşın',
    whatsapp: 'WhatsApp',
    contactWorkingHours: 'Çalışma Saatlerimiz',
    contactInfoText:
        'KeiKei ürünleri ve diğer tüm konular hakkında soru, görüş ve önerilerinizi aşağıdaki form üzerinden bize iletebilirsiniz.',
    callCenter: 'Çağrı Merkezi',
    monday: 'Pazartesi',
    friday: 'Cuma',
    customizeCookie: 'Kişiselleştir',
    customizePromotionCookie: 'Tanıtım Çerezlerini Özelleştir',
    keikeiAds: 'KeiKei Reklamları',
    thirdPartyAds: 'Üçüncü Parti Reklamları',
    customizeThirdPartyAds: 'Üçüncü Parti Reklamlarını Özelleştirme',
    acceptCookie: 'Çerezleri Kabul Et',
    saveCustomCookie: 'Özelleştirilmiş Çerezleri Kaydet',
    advertisingCookies: 'Reklam ve Opsiyonel Çerezler',
    cookiePolicyText:
        'Sizlere daha iyi bir deneyim sunabilmek için sitemizde çerezler kullanılmaktadır. Detaylı bilgi:',
    advertisingCookieText:
        'Ayrıca çerezleri, KeiKei’de ilgilerinize ilişkin belirli reklamlar da dahil olmak üzere belirli reklam türlerini sunmak ve diğer tanıtım, istatistik, pazarlama amaçları için kullanmaktayız. KeiKei hizmetlerini kullanırken etkileşimde bulunduğunuz onaylı üçüncü taraflar da çerez bırakabilir. Üçüncü taraflar, ilgilerinize ilişkin reklamlar dahil olmak üzere içerik sunma, reklamlarının etkililiğini ölçme ve KeiKei adına hizmetler yürütme süreci sırasında çerez kullanır.\n' +
        '\n' +
        'Aşağıda KeiKei ve üçüncü parti çerezleri topluca veya ayrı ayrı kabul edebilir, ret edebilir veya önceki tercihlerinizi değiştirebilirsiniz.',
    consentPurposes: 'Rıza Amaçları:',
    consentPurpose1: 'Cihaz üzerinde bilgi saklayın ve/veya bilgiye erişin',
    consentPurpose2: 'Temel reklamları seçin',
    consentPurpose3: 'Kişiselleştirilmiş reklamlar seçin',
    consentPurpose4: 'Reklam performansını ölçün',
    consentPurpose5: 'Ürünleri geliştirin ve iyileştirin',
    specialPurposes: 'Özel Amaçlar:',
    specialPurpose1:
        ' Güvenliği sağlayın, dolandırıcılığı önleyin ve hataları giderin',
    specialPurpose2: 'Teknik olarak reklam veya içerik sunun',
    features: 'Özellikler',
    feature1: 'Çevrimdışı veri kaynaklarını eşleştirin ve birleştirin',
    feature2: 'Farklı cihazları bağlayın',
    clickHere: 'buraya tıklayın',
    sort: 'Sırala',
    listingView: 'Listeleme Görünümü',
    product: 'Ürün',
    filter: 'Filtrele',
    readMore: 'Devamını Oku',
    apply: 'uygula',
    okay: 'okay',
    resetLinkSended: 'Sıfırlama linki başarılı bir şekilde gönderildi.',
    userNotFound: 'Kayıtlı Kullanıcı Bulunamadı.',
    incorrectInformation: 'E-posta adresiniz ve/veya şifreniz hatalı.',
    emailUsedBefore: 'E-posta adresi daha önce kullanılmış.',
    signUpWith: 'ile Üye Ol',
    loginWith: 'ile Giriş Yap',
    passwordChanged: 'Şifre başarı ile değiştirildi!',
    checkPassword: 'Şifrenizi kontrol ediniz!',
    favoriteProduct: 'Favori Ürün',
    chooseASize: 'Beden Seç',
    addAddress: 'Adres Ekle',
    applyCouponInBasket: 'Sepetteki Ürünlere Uygula',
    myOrders: 'Siparişlerim',
    myFavorites: 'Favorilerim',
    myAddresses: 'Adreslerim',
    addresses: 'Adresler',
    myDiscountCoupons: 'İndirim Kuponlarım',
    savedCreditCards: 'Kayıtlı Kartlarım',
    changePassword: 'Şifre Değiştir',
    profileInformations: 'Profil Bilgilerim',
    help: 'Yardım',
    logOut: 'Çıkış Yap',
    cartContinueShopping: 'Alışverişe Devam Et',
    cartDeleteText: 'adlı ürünü sepetinizden çıkarmak istiyor musunuz?',
    cartNoProduct: 'Sepetinizde ürün bulunmamaktadır !',
    cartDiscountText: 'Sepette',
    cartDiscountAmount: 'ekstra %10 indirim kazanmak için ',
    cartWorthProduct: "'lik ürün ekleyin",
    cartSummary: 'SİPARİŞ ÖZETİ',
    cartTotal: 'TOPLAM',
    cartDeleteAndFav: 'SİL VE FAVORİLERE EKLE',
    cartDeleteAndFavText:
        'adlı ürünü sepetinden çıkardıktan sonra favorilerine eklemek ister misin?',

    cartAddBox: 'KUTU EKLE',
    cartGiftBoxText:
        'Sevdiklerinize siparişini özel KeiKei Kutuları içinde yollamak ister misiniz ?',
    cartEnterDiscountCode: 'İndirim Kodu',
    cartChooseDiscountCode: 'İndirim Kuponu Seç',
    noDiscountCode: 'İndirim kuponunuz bulunmamaktadır.',
    cartProductTotal: 'Ürün Toplamı',
    shipping: 'Kargo',
    membershipAgreement: 'Üyelik Sözleşmesi',
    PersonalDataProtectionAuthority:
        'Kişisel Verilerin İşlenmesi Hakkında Aydınlatma Metni`nde',
    membershipInfo:
        'WEBSİTESİ ÜYELİĞİ KAPSAMINDA KİŞİSEL VERİLERİNİN İŞLENMESİNE İLİŞKİN AYDINLATMA METNİ',
    membershipInfoMetaTitle: 'Üyelik Ön Bilgilendirme',
    metaTitle: ' Modelleri ve Fiyatları ',
    customCookiePolicyText:
        'Alışveriş deneyiminizi iyileştirmek ve hizmetlerimizi daha iyi hale getirmek için yasal düzenlemelere uygun çerezler (cookies) kullanıyoruz. Web sitemizi ziyaret etmeye ve alışveriş yapmaya devam etmeniz durumunda, operasyonel ve zorunlu çerezleri kullanmaya devam edeceğiz. Reklam ve Opsiyonel çerezler yönünde ise yalnızca izin verdiğiniz çerezleri kullanmaya devam edeceğiz. Çerez tercihlerinizi düzenlemek için aşağıdaki bağlantıları kullanabilir veya çerezler hakkında detaylı bilgi almak için Çerez Politikamızı inceleyebilirsiniz. Çerez politikamızı incelemek için ',
    categoryMetaText:
        'Birbirinden şık ve trend elbise modelleri avantajlı fiyat seçenekleri ile keikei.com"da. Uygun fiyat ve hızlı kargo seçenekleri ile KeiKei fırsatlarını kaçırmayın!',
    categoryMetaText2:
        "Yeni sezon kadın elbise modelleri keikei.com ile kapınızda. Türkiye'nin her yerine ücretsiz kargo seçeneği ile yolluyoruz. Son trendler, moda renklerde göz alıcı modellerden biri sizin için.",
    facebookText:
        'Facebook, insanlarla bağlantı kurmanızı sağlayan bir sosyal ağ ve sosyal medya sitesidir. İşletmeler ve bireyler için kitlelerini büyütmek ve erişimlerini genişletmek için çok çeşitli hizmetler sunar.',
    pixelText:
        'Facebook Pixel, esas olarak dönüşüm izleme, optimizasyon ve yeniden pazarlama için kullanılan bir hizmettir.',
    gAnalyticsText:
        'Google Analytics, web sitesi trafiğini izleyen ve ölçen bir web analizi hizmetidir. Web yöneticilerine faydalı bilgiler sağlayan gelişmiş işlevlere sahip bir dizi analiz özelliğine sahiptir. Ayrıca, Adwords ile entegre edildikten sonra Pazar Analizi ve Dönüşüm hedefleri belirlemek için de kullanılır.',
    gTagManagerText:
        'Google Tag Manager, verimli analizler için kurumsal düzeyde bir etiket yönetimi çözümüdür. Üçüncü taraf analizlerini destekler ve gelişmiş etiket yönetimi teknolojileri sunar.',
    gReMarketingText:
        'Google Remarketing Tag, sitedeki tüm sayfalara tek bir etiket yerleştirmeye olanak tanıyarak kolayca yeniden pazarlama listeleri oluşturmaya yardımcı olur.',
    payWithSavedCard: 'Kayıtlı Kartla Öde',
    showDiscountedProducts: 'İndirimli Ürünleri Göster',
    howDoIReturn: 'Nasıl İade Ederim?',
    returnDescription: `
    Değerli Müşterilerimiz,

    Sizlere daha iyi hizmet verebilmek için iade politikamızı güncelledik! Artık alışveriş deneyiminiz daha da kolaylaştı. İşte yeni iade sürecimiz:

    Kolay İade Süreci:

    1. İade Talebi: Ürününüzü teslim aldıktan sonra 14 gün içinde iade talebinde bulunabilirsiniz. İade talebinizi, destek@keikei.com e-posta adresine veya +90 850 259 1441 numaralı whatsapp hattımıza mesaj atarak iletebilirsiniz.

    2. İade Onayı: Talebinizi aldıktan sonra, en kısa sürede iade işleminiz için gerekli bilgileri ve iade kodunu içeren bir onay mesajı alacaksınız.

    3. Ürünü Gönderin: İade kodunu aldıktan sonra, ürünü eksiksiz ve hasarsız bir şekilde, tüm aksesuarları ve ambalajıyla birlikte belirtilen adrese geri göndermelisiniz. 

    4. Ücretsiz Kargo: İade işleminiz için kargo masraflarını biz karşılıyoruz! Ürünü iade ederken, kargo ücretini düşünmenize gerek yok.

    5. İade İşlemi: İade edilen ürünler, tarafımıza ulaştıktan sonra en kısa sürede incelenecek ve iade işleminiz başlatılacaktır. İade tutarınız, ödemenizi yaptığınız yöntemle geri ödenecektir.

    Önemli Notlar:
    - İade edilecek ürünlerin kullanılmamış, hasarsız ve orijinal ambalajında olması gerekmektedir.
    - İade süresi içinde talep edilmediği takdirde, iade hakkınızı kaybedebilirsiniz.

    Müşteri memnuniyetini önceliğimiz olarak görüyoruz ve alışveriş deneyiminizi daha keyifli hale getirmek için buradayız! Herhangi bir sorunuz olursa, bizimle iletişime geçmekten çekinmeyin.

    Teşekkür ederiz!
    `,
    returnPolicyDescription:
        'İade süresi teslimat tarihinden itibaren 14 gündür. İade edeceğiniz ürünün, paketi hasar görmemiş, kullanılmamış ve kullanım hatası sonucu zarar görmemiş olması gerekmektedir. Ürünler kullanılmış, yıpranmış, yıkanmış bir şekilde bize ulaşırsa size geri göndermek zorunda kalabiliriz. Bu durumda ücret iadesi yapılmaz. İade etmek istediğiniz ürün/ürünleri tüm aksesuarları ve orijinal kutusu ile irsaliye ile beraber iade etmeniz gerekmektedir.',
    returnPolicyDescription2:
        'İki ve üzerine %50 indirim kampanyasında iade edilen ürünlerde tekli iade işlemi gerçekleşmemektedir. Kampanya dahilinde alınan ürünler toplu şekilde iade edilebilir.',
    home: 'Anasayfa',
    and: 've',
    agreementAccept: '`ni okudum ve kabul ettim.',
    agreementAccept1:
        'Yalnızca Belirtmiş/Doldurmuş olduğum ülke, doğum tarihi, iletişim ve cinsiyet bilgilerimin',
    agreementAccept2:
        'belirtilen amaçlar doğrultusunda işlenmesine rıza veriyorum',
    orderReceived: 'SİPARİŞİNİZ ALINMIŞTIR',
    orderNumber: 'Sipariş Numarası',
    colour: 'Renk',
    orderDate: 'Sipariş Tarihi',
    orderSummary: 'Sipariş Özeti',
    buyer: 'Alıcı',
    amount: 'Tutar',
    orderDetail: 'Sipariş Detayı',
    searchResult: 'Arama Sonucu',
    minCharWarning: 'En az 2 karakter içermelidir.',
    passwordMinCharWarning: 'Şifre en az 5 karakter olmalıdır.',
    enterValidMail: ' Lütfen geçerli bir e-posta adresi giriniz.',
    passwordTooLong: 'Parola en fazla 32 karakterden oluşmalı.',
    validPhoneNumber: 'Geçerli bir telefon numarası giriniz.',
    addressTitleTooLong: 'Adres başlığı en fazla 16 karakter olmalıdır.',
    addressTitleTooShort: 'Adres en az 3 karakterden oluşmalıdır.',
    passwordsNotSame: 'Şifreler aynı değil.',
    mustAcceptTerms: 'Şartları kabul etmelisiniz.',
    requiredMessage: 'Bu alan zorunludur.',
    giftBox: 'Hediye Kutusu',
    cartTypeDiscountCode: 'Kupon kodu gir',
    productDiscount: 'Ürün İndirimi',
    coupon: 'Kupon İndirimi',
    discountCoupon: 'İndirim Kuponu',
    last: 'Son',
    loadMore: 'Daha Fazla Yükle',
    removeBox: 'Kutuyu Kaldır',
    selectCoupon: 'Kupon Seç',
    cartConfirm: 'Siparişi Tamamla',
    pay: 'Sepeti Onayla',
    enterCoupon: 'Kupon Kodu Girin',
    addressTooShort: 'Adres  en az 8 karakterden oluşmalıdır.',
    zipCodeTooShort: 'Posta kodu 3-10 karakterden oluşmalıdır.',
    infoChanged: 'Bilgiler başarıyla değiştirildi',
    passwordAreNotSame: 'Şifreler aynı değil.',
    passwordIsNotValid: 'Şifre geçerli değil.',
    change: 'Değiştir',
    deliveryAndPaymentModal: 'Mesafeli Satış Sözleşmesi',
    haveToAccept: 'Ödemeyi tamamlamak için bu sözleşmeleri kabul etmelisiniz.',
    cardOwner: 'Kart Sahibi',
    productSlug: 'urun',
    allCategories: 'Tüm Kategoriler',
    noFavoriteProduct: 'Favorilerinizde ürün bulunmamaktadır.',
    leftMenuTitle: 'MENÜ',
    leftMenuDresses: 'ELBİSELER',
    barcode: 'Barkod',
    guestLogin: 'MİSAFİR GİRİŞİ',
    continue: 'Devam Et',
    scrollTop: 'Yukarı Çık',
    anErrorOccurred: 'BİR SORUN OLUŞTU',
    tryAgainLater:
        'Daha sonra tekrar deneyebilir ya da aşağıdaki butonu kullanarak alışverişe devam edebilirsiniz.',
    runningOut: 'Tükeniyor',
    search: 'Ara',
    selectCountry: 'Ülke Seçiniz',
    selectState: 'Şehir Seçiniz',
    selectProvince: 'İlçe Seçiniz',
    noAddress: 'Kayıtlı adres bulunmamaktadır.',
    noCoupon: 'Aktif olan indirim kuponunuz bulunmamaktadır.',
    noSavedCard: 'Kayıtlı kredi kartı bulunamadı.',
    day: 'Gün',
    hour: 'Saat',
    minute: 'Dakika',
    second: 'Saniye',
    shippingFee: '0 TL',
    or: 'ya da',
    couponUse:
        'Kullanmak İstediğiniz kuponları indirim sayfasında uygulayabilirsiniz',
    contactMessage: 'Mesaj',
    identity: 'Kimlik',
    pleaseLoginForAddCoupon: 'Kupon kullanmak için giriş yapmalısınız.',
    storageAndDestroy: 'Saklama ve İmha Politikası',
    relatedPersonForm: 'İLGİLİ KİŞİ BAŞVURU FORMU',
    customerClarifitacion: ' Müşteri ve Ziyaretçi Aydınlatma Metni',
    zipCode: 'Posta Kodu',
    productAddedToCart: ' Ürün Sepetinize Eklendi.',
    login: 'Giriş Yapın',
    mailAlreadyExist: 'Bu mail adresi ile bir üyelik mevcut.',
    tryAnotherMail: ' veya başka bir mail adresi deneyin.',
    securePayment: 'Güvenli Ödeme',
    checkCreditCardInfo: 'Kart Bilgilerinizi Kontrol Ediniz',
    userAgreement: 'Kullanıcı Sözleşmesi',
    listingCategoryDescriptionCategory: 'kategorisinde',
    listingCategoryDescriptionCount: 'adet ürün bulundu.',
    checkoutCreateAddress: 'YENİ ADRES OLUŞTUR',
    page: 'Sayfa',
    keikei: 'KeiKei',
    canonicalText: '?page=',
    firstName: 'Ad',
    lastName: 'Soyad',
    close: 'Kapat',
    couponCodeError: 'Kupon kodu hatalı.',
    campaignTitle: 'Hoşgeldin İndirimi',
    models: 'Modelleri',
    whenAddTwoProduct: 'Sepete iki ürün eklediğinde',
    onlineShopping: 'KeiKei - Online Alışveriş',
    openInApp: 'KeiKei uygulamasında aç',
    open: 'AÇ',
    products: 'Ürün',
    suggestions: 'Öneriler',
    authInfoAggregator:
        'Yalnızca Belirtmiş/Doldurmuş olduğum ülke, doğum tarihi, iletişim ve cinsiyet bilgilerimin Kişisel Verilerin İşlenmesi Hakkında Aydınlatma Metninde belirtilen amaçlar doğrultusunda işlenmesine rıza veriyorum.',
    preInforming: 'Ön Bilgilendirme',
    sizeTable: 'Beden Tablosu',
    sizeTableSizes: 'BEDENLER',
    sizeTableChest: 'GÖĞÜS/cm',
    sizeTableWaist: 'BEL/cm',
    sizeTableHip: 'KALÇA/cm',
    catchTheOffers: 'keikei.com’de size özel sunulan fırsatları yakalayın.',
    completeOrderWithoutLogin: 'Siparişinizi üye olmadan tamamlayın.',
    addressTitleHome: 'Ev, İş, vb.',
    fastDelivery: 'HIZLI TESLİMAT',
    relatedProducts: 'İlgili Ürünler',
    productTitleSeoText: ' Fiyatı ve Taksit Seçenekleri ',
    productDetails: 'Ürün Detayları',
    joinWaitlist: 'Bekleme Listesine Katıl',
    beNotified:
        'Ürün çıktığında haberdar olmak için lütfen aşağıya e-posta adresinizi girin.',
    beNotifiedUser: 'Ürün çıktığında haberdar olmak ister misiniz?',
    interestedIn: 'BUNLAR DA İLGİNİZİ ÇEKEBİLİR',
    all: 'Tüm',
    listResult: 'sonuçlarını listele',
    applyFilters: 'FİLTRELERİ UYGULA',
    clearFilters: 'FİLTRELERİ TEMİZLE',
    chooseSize: 'Lütfen bir beden seçin',
    allSlug: 'tum-urunler',
    stockOut: 'Tükendi',
    done: 'tamam',
    wantToNotify:
        ' Ürün stoğu yenilendiğinde sizi haberdar etmemizi ister misiniz?',
    discountBadgeText1: 'Sepette 1000 TL ve katlarına anında ',
    discountBadgeText2: '100`er TL indirim.',
    niceChooseText: 'Güzel Seçim! Bu Elbise sana çok yakışacak ✨',
    keikeiTitle: 'KeiKei |',
    returnConditions: 'İade Şartları',
    backToMyOrders: 'SİPARİŞLERİME DÖN',
    selectProducts: 'ÜRÜNLERİ SEÇ ve DEVAM ET',
    easyReturnRequest: 'Kolay İade',
    continueShopping: 'ALIŞVERİŞE DEVAM ET',
    returnPackage: 'İade Paketi',
    changeProduct: 'Ürünleri değiştir',
    confirmReturn: 'İadeyi Talebini Onayla',
    selectReturnedItem: 'Lütfen iade etmek istediğiniz ürünleri seçiniz.',
    sendTheProduct:
        'Ürünü tüm aksesuarları ile birlikte orjinal kutusu bozulmayacak şekilde kargo poşeti veya korunaklı bir kutu içerisine koyarak gönderin.',
    packWithInvoice:
        'Sadece aynı iade koduna sahip ürünleri fatura ile birlikte paketleyin.',
    giveThePackage:
        'Paketi iade kodunuzla birlikte en geç 7 gün içinde Yurtiçi Kargo’ya verin.',
    sevenDays: '7 gün içerisinde',
    applyForRefund:
        'kullanmazsanız tekrar iade başvuru yapmanız gerekmektedir.',
    makeProcess: 'İşlemlerin yapılacağı ',
    cargoInfo: 'kargo firması bilgileri',
    reasonReturn: 'İade Nedeni',
    requestReceived: 'İADE TALEBİ ALINMIŞTIR',
    returnCode: 'İade Onay Kodu',
    cargoCompany: 'Kargo Firması',
    followingProducts:
        'Aşağıdaki ürünler için geri iade talebinde bulundunuz. ',
    approve: 'Onay veriyor musunuz?',
    amountRefunded: 'Geri Yatırılacak Tutar',
    amountCoupon: 'Yatırılacak Kupon Tutarı',
    contractNo: 'Sözleşme No',
    infoNotValid: 'Bilgileriniz geçerli değil',
    orderNo: 'Sipariş No',
    orderTracing: 'Sipariş Takip',
    orderTracingInfo:
        'Siparişlerinizi üye girişi yaparak da kolaylıkla takip edebilirsiniz.',
    returnIn14Days: '14 Gün içinde iade hakkı',
    freeCargo: 'Tüm Türkiye’ye ücretsiz kargo',
    installmentOption: 'Taksitli ödeme seçenekleri',
    originalProduct: '%100 Orjinal ürün garantisi',
    specialsOffersForMember:
        'keikei.com’da size özel fırsatların keyfini çıkarın.',
    cargoTracking: 'Kargo Takibi',
    whereIsMyCargo: 'Kargom Nerede?',
    viewGallery: 'Tüm galeriyi görüntüle',
    shareStyle:
        ' KeiKei markalı kıyafetlerinizle oluşturduğunuz kombinleriniz takipçilerimizi çok mutlu ediyor. Tarzını göster, kombinini paylaş.',
    buy: 'SATIN AL',
    choice: 'Tercihler',
    updateTheChoice: 'TERCİHLERİ GÜNCELLE',
    language: 'Dil',
    productAddedFail:
        'Ürün stoğu bittiği için veya başkasının sepetinde olduğu için eklenemedi. Lütfen daha sonra tekrar deneyin.',
    wrongPlace: 'Yanlış yere geldiniz',
    single: 'Tek',
    payment: 'Çekim',
    tl: '₺',
    returnInformation: 'İade Bilgileri',
    contactAgreement: 'İletişim Kutusu Ziyaretçi Aydınlatma Metni',
    contactNotifyTitle: 'Mesajınız gönderilmiştir!',
    contactNotifyDesc: 'İlginiz için teşekkür ederiz.',
    paypalWarning: 'Paypal ile ödeme yapmak için sözleşmeleri onaylamalısınız.',
    paymentFail: 'Ödeme başarısız.',
    days: 'Gün',
    hours: 'Saat',
    minutes: 'Dakika',
    seconds: 'Saniye',
    newCollection: 'YENİ KOLEKSİYONUMUZUN YAYINLANMASINA...',
    goatSlug: '/goat',
    allProductSlug: '/tum-urunler',
    dressSlug: '/elbise',
    lastChance: 'Son Fırsat',
    hurryUp: 'Acele edin ve çok geç kalmadan siparişinizi verin!',
    februaryDate: '1 Şubatta yeni fiyatlar.',
    priceIncreaseNotification: '1 ŞUBATTA SATIŞ FİYATLARIMIZ ARTACAKTIR.',
    favoriteLoginAlert: 'Favoriye eklemek için giriş yap',
    in: ' ',
    defectiveSale: 'Defolu Satış',
    defectiveWarning: 'Bu ürün için  iade ve değişim işlemi yapılmamaktadır.',
    defectiveWarningListing:
        'Bu kategorideki ürünler defolu olduğundan, %80 indirimle satılmaktadır. Bu kategori için iade ve değişim işlemi yapılmamaktadır.',
    orderTracking: 'Sipariş Takibi',
    followUs: 'Bizi Takip Edin',
    downloadApp: 'Uygulamamızı İndirin',
    popularSearches: 'Popüler Aramalar',
    freeShippingTurkiye: 'Tüm Türkiye’ye ücretsiz kargo',
    installmentPayment: 'Taksitli ödeme seçenekleri',
    secureShopping: 'Güvenli alışveriş',
    height: 'Boy',
    bust: 'Göğüs',
    hips: 'Kalça',
    size: 'Ürün',
    waist: 'Bel',
    modelMeasurements: 'Model Ölçüleri',
    shopNowMetaDesc:
        'Ücretsiz kargo ve güvenli alışveriş fırsatları için hemen sipariş verin!',
    inKeiKeiCo: "keikei.com' da",
    inKeiKei: "KeiKei'de!",
    clickKeiKeiCo: 'hemen keikei.com adresine tıkla',
    mostStylish: 'en şık',
    for: 'için',
    selectRefundReason: 'İade Nedeni Seçiniz',
    seeAllProducts: 'Tüm Ürünleri Gör',
    accountMetaDesc:
        'KeiKei hesap sayfasına hoş geldiniz. Hesabınızı yönetmek, sipariş geçmişinizi ve takibinizi kontrol etmek için buradayız.',
    aboutUsMetaDesc:
        'KeiKei, kadın giyim sektöründe moda tutkunları için en yeni ve kaliteli ürünleri uygun fiyatlarla sunan bir e-ticaret sitesidir. Daha fazla bilgi için hakkımızda sayfamızı ziyaret edin.',
    contactMetaDesc:
        'KeiKei müşteri hizmetleri ekibi olarak her zaman sizinle iletişimdeyiz. Bize ulaşın, sorularınızı sorun ve herhangi bir konuda yardım alın.',
    privacyPolicyMetaDesc:
        'KeiKei aydınlatma metni hakkında daha fazla bilgi edinmek için sayfamızı ziyaret edin.',
    cookiePolicyMetaDesc:
        'KeiKei çerez politikası hakkında daha fazla bilgi edinmek için sayfamızı ziyaret edin.',
    membershipInfoMetaDesc:
        'KeiKei üyelik öncesi bilgilendirme sayfasına hoş geldiniz. Üyelik ile ilgili bilmeniz gereken tüm detaylar burada.',
    faqMetaDesc:
        'KeiKei sıkça sorulan sorular sayfası ile müşterilerimizin en sık sorduğu sorulara cevap veriyoruz.',
    membershipAgreementMetaDesc:
        'KeiKei üyelik sözleşmesi hakkında daha fazla bilgi edinmek için sayfamızı ziyaret edin.',
    distanceSaleContractMetaDesc:
        'KeiKei mesafeli satış sözleşmesi hakkında daha fazla bilgi edinmek için sayfamızı ziyaret edin.',
    distanceSalesPreInfoMetaTitle: 'Mesafeli Satış Ön Bilgilendirme Formu',
    distanceSalesPreInfoMetaDesc:
        'KeiKei mesafeli satış ön bilgilendirme sayfasına hoş geldiniz. Mesafeli satış hakkında bilmeniz gerekenler burada.',
    storageAndDestroyMetaDesc:
        'KeiKei saklama ve imha politikası hakkında daha fazla bilgi edinmek için sayfamızı ziyaret edin.',
    relatedPersonFormMetaTitle: 'İlgili Kişi Başvuru Formu',
    relatedPersonFormMetaDesc:
        'KeiKei ilgili kişi başvuru formu sayfasına hoş geldiniz. Kişisel verilerinizi yönetmek için buradayız.',
    changeAndReturnMetaTitle: 'Değişim ve İade',
    changeAndReturnMetaDesc:
        "KeiKei'de satın aldığınız ürünlerde değişim ve iade işlemleri hakkında bilmeniz gerekenler burada.",
    customCookiesMetaTitle: 'Özel Çerezler',
    customCookiesMetaDesc:
        'KeiKei özel çerezler hakkında daha fazla bilgi edinmek için sayfamızı ziyaret edin.',
    allCategoriesMetaDesc:
        "KeiKei'de kadın giyiminde son moda ürünler bulunur. Elbiseler, bluzlar, pantolonlar, etekler, tulumlar ve daha fazlası. Tüm kategorilerimizi inceleyin.",
    termsConditionsMetaDesc:
        "KeiKei'de yüksek kaliteli ürünler ve müşteri memnuniyeti bir arada. Alışveriş sürecinizde ihtiyaç duyabileceğiniz tüm bilgiler için şartlar ve koşullar sayfamıza göz atın.",
    rightsAndAssignmentsMetaDesc:
        "On KeiKei's 'rights and assignments' page, all your rights and duties are clearly stated. If you want to shop safely while protecting your rights, visit our page for details.",
    notSignupYet: 'Henüz üye olmadınız mı?',
    signupNowCampaign: 'Hemen üye olun, ilk alışverişinize özel',
    getDiscount: 'indirimden yararlanın!',
    newUser: 'Yeni Üyelik',
    newUserUpper: 'YENİ ÜYELİK',
    createAccount: 'Hesap Oluştur',
    continueWithoutRegister: 'Üye Olmadan Devam Et',
    newRegister: 'Yeni Üyelik Oluştur',
    registerNow: 'Üyelik fırsatlarından yararlanmak için hemen kaydolun.',
    freeShippingForTurkey: 'Tüm Türkiye’ye ÜCRETSİZ kargo',
    installmentPaymentOption: 'TAKSİTLİ ödeme seçenekleri',
    secureShop: 'GÜVENLİ alışveriş',
    newSeasonText: 'Modelleri ve Fiyatları',
    categorySEOTitle: 'Modelleri ve Fiyatları',
    message: 'Mesaj',
    complaintSuccess: 'Mesajınız başarıyla alındı !',
    complaintMessage: 'Keikei ürünleri ve diğer tüm konular hakkında soru, görüş ve önerilerinizi aşağıdaki form üzerinden bize bildirebilirsiniz.',
    wrongProduct: 'Yanlış Ürün',
    missingProduct: 'Eksik Ürün',
    wrongSize: 'Yanlış Beden',
    wrongColor: 'Yanlış Renk',
    wrongInformation: 'Yanlış Bilgi',
    wrongOrder: 'Yanlış Sipariş',
    notLike: 'İstenmeyen Ürün',
    other: 'Diğer',
    returnMessage: 'Merhaba , {order_number} numaralı siparişimi, {reason} nedeniyle iade etmek istiyorum. İade süreci hakkında bilgilendirilmek ve gereğinin yapılmasını rica ederim. Teşekkür ederim, iyi çalışmalar.'
};
