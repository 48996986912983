import { Footer } from '@components/common';
import { BankIcons } from '@components/ui';
import useTranslation from '@hooks/useTranslation';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MuteIcon, SpeakerIcon } from '@components/icons';
import { useWindowSize } from 'react-use';

const ParallaxSlider = () => {
    const { locale } = useTranslation();
    const banners = [
        {
            id: 1,
            slug: {
                tr: '/tum-urunler',
                en: '/all',
            },

            photo: {
                tr: 'https://cloud.keikei.com/cdn/banners/0225/1.jpg',
                en: 'https://cloud.keikei.com/cdn/banners/0225/1.jpg',
            },
            mobilePhoto: {
                tr: 'https://cloud.keikei.com/cdn/banners/0225/mobil1.jpg',
                en: 'https://cloud.keikei.com/cdn/banners/0225/mobil1.jpg',
            },
        },
        {
            id: 2,
            slug: {
                tr: '/city-chic',
                en: '/city-chic',
            },

            photo: {
                tr: 'https://cloud.keikei.com/cdn/banners/0225/2.jpg',
                en: 'https://cloud.keikei.com/cdn/banners/0225/2.jpg',
            },
            mobilePhoto: {
                tr: 'https://cloud.keikei.com/cdn/banners/0225/mobil2.jpg',
                en: 'https://cloud.keikei.com/cdn/banners/0225/mobil2.jpg',
            },
        },
        {
            id: 3,
            slug: {
                tr: '/en-yeniler',
                en: '/new-in',
            },

            photo: {
                tr: 'https://cloud.keikei.com/cdn/banners/0225/3.jpg',
                en: 'https://cloud.keikei.com/cdn/banners/0225/3.jpg',
            },
            mobilePhoto: {
                tr: 'https://cloud.keikei.com/cdn/banners/0225/mobil3.jpg',
                en: 'https://cloud.keikei.com/cdn/banners/0225/mobil3.jpg',
            },
        },
        {
            id: 4,
            slug: {
                tr: '/indirim',
                en: '/sale',
            },

            photo: {
                tr: 'https://cloud.keikei.com/cdn/banners/0225/4.jpg',
                en: 'https://cloud.keikei.com/cdn/banners/0225/4.jpg'
            },
            mobilePhoto: {
                tr: 'https://cloud.keikei.com/cdn/banners/0225/mobil4.jpg',
                en: 'https://cloud.keikei.com/cdn/banners/0225/mobil4.jpg',
            },
        },
        {
            id: 5,
            slug: {
                tr: '/tum-urunler',
                en: '/all',
            },

            photo: {
                tr: 'https://cloud.keikei.com/cdn/banners/0225/5.jpg',
                en: 'https://cloud.keikei.com/cdn/banners/0225/5.jpg'
            },
            mobilePhoto: {
                tr: 'https://cloud.keikei.com/cdn/banners/0225/mobil5.jpg',
                en: 'https://cloud.keikei.com/cdn/banners/0225/mobil5.jpg',
            },
        },
        {
            id: 6,
            type: 'footer',
        },
    ];

    const [currentSection, setCurrentSection] = useState(0);
    const [prevTransform, setPrevTransform] = useState(0);
    const [nextTransform, setNextTransform] = useState(0);
    const [transform, setTransform] = useState(0);
    const { width } = useWindowSize();
    const [isScrolling, setIsScrolling] = useState(false);
    const [muted, setMuted] = useState(true);
    const toggleMute = () => {
        setMuted(!muted);
    };
    let previousTouchMove = null;

    const handleScroll = (e) => {
        e.preventDefault();
        setMuted(true);
        if (!isScrolling) {
            setIsScrolling(true);
            const delta = e.deltaY;

            if (delta > 0 && currentSection < banners.length - 1) {
                setCurrentSection(
                    currentSection >= banners.length
                        ? banners.length - 1
                        : currentSection + 1,
                );
                setPrevTransform(transform);
                setNextTransform(100 * (currentSection + 1));
                setTransform(100 * (currentSection + 1));
            } else if (delta < 0 && currentSection > 0) {
                setCurrentSection(currentSection - 1);
                setTransform(
                    100 * (currentSection === 1 || 0 ? 0 : currentSection - 1),
                );
                setPrevTransform(
                    100 * (currentSection === 1 || 0 ? 0 : currentSection - 2),
                );
                setNextTransform(
                    100 * (currentSection === 1 || 0 ? 0 : currentSection - 1),
                );
            }
            setTimeout(() => {
                setIsScrolling(false);
            }, 1300);
        }
    };

    const handleTouchMove = (e) => {
        e.preventDefault();
        if (previousTouchMove !== null && !isScrolling) {
            setIsScrolling(true);
            setMuted(true);
            if (e.touches[0].clientY < previousTouchMove) {
                setCurrentSection(
                    currentSection >= banners.length
                        ? banners.length - 1
                        : currentSection + 1,
                );

                setCurrentSection(
                    currentSection >= banners.length - 1
                        ? currentSection
                        : currentSection + 1,
                );
                setPrevTransform(transform);
                setNextTransform(
                    currentSection >= banners.length - 1
                        ? 100 * currentSection
                        : 100 * (currentSection + 1),
                );
                setTransform(
                    currentSection >= banners.length - 1
                        ? 100 * currentSection
                        : 100 * (currentSection + 1),
                );
            } else {
                setCurrentSection(currentSection <= 0 ? 0 : currentSection - 1);
                setTransform(
                    100 * (currentSection === 1 ? 0 : currentSection - 1),
                );

                setPrevTransform(
                    100 * (currentSection === 1 || 0 ? 0 : currentSection - 2),
                );
                setNextTransform(
                    100 * (currentSection === 1 || 0 ? 0 : currentSection - 1),
                );
            }
            setTimeout(() => {
                setIsScrolling(false);
            }, 1000);
        } else {
            previousTouchMove = e.touches[0].clientY;
        }
    };

    useEffect(() => {
        window.addEventListener('wheel', handleScroll, { passive: false });
        window.addEventListener('touchmove', handleTouchMove, {
            passive: false,
        });

        previousTouchMove = null;

        return () => {
            window.removeEventListener('wheel', handleScroll);
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, [currentSection, handleScroll, handleTouchMove, isScrolling]);

    return (
        <div>
            <SliderWrapper transform={transform} prevTransform={prevTransform}>
                <Slider>
                    {banners.map((banner) =>
                        banner.type === 'footer' ? (
                            <Slide
                                key={banner.id}
                                transform={
                                    banner.id === currentSection
                                        ? transform
                                        : banner.id < currentSection
                                        ? prevTransform
                                        : nextTransform
                                }
                                background="#ffffff"
                                footerSlide
                            >
                                <Footer />
                                <BankIcons />
                            </Slide>
                        ) : banner.type === 'video' ? (
                            <Slide
                                key={banner.id}
                                transform={
                                    banner.id === currentSection
                                        ? transform
                                        : banner.id < currentSection
                                        ? prevTransform
                                        : nextTransform
                                }
                                background="#ffffff"
                                footerSlide
                            >
                                <Link
                                    to={banner.slug[locale]}
                                    href={banner.slug[locale]}
                                >
                                    <VideoContainer>
                                        <video
                                            autoPlay
                                            loop
                                            {...(muted && {
                                                muted: true,
                                            })}
                                            playsInline
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                maxHeight: '100vh',
                                            }}
                                        >
                                            <track kind="captions" />
                                            <source
                                                src={
                                                    width > 1024
                                                        ? banner.video.url
                                                        : banner.mobileVideo.url
                                                }
                                                type="video/mp4"
                                            />
                                        </video>
                                        <ImageContainer>
                                            <img
                                                src="/whitebadge.png"
                                                alt="city-rendez-vous"
                                            />
                                        </ImageContainer>
                                    </VideoContainer>
                                </Link>{' '}
                                <MuteWrapper onClick={toggleMute}>
                                    <MuteContent>
                                        {muted ? <MuteIcon /> : <SpeakerIcon />}
                                    </MuteContent>
                                </MuteWrapper>
                            </Slide>
                        ) : (
                            <Slide
                                key={banner.id}
                                transform={
                                    banner.id === currentSection
                                        ? transform
                                        : banner.id < currentSection
                                        ? prevTransform
                                        : nextTransform
                                }
                            >
                                <Link
                                    to={banner.slug[locale]}
                                    href={banner.slug[locale]}
                                >
                                    <SlideImage
                                        src={banner.photo[locale]}
                                        mobileSrc={banner.mobilePhoto[locale]}
                                    />
                                </Link>
                            </Slide>
                        ),
                    )}
                </Slider>
            </SliderWrapper>
        </div>
    );
};

export default ParallaxSlider;

const SliderWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    @media (max-width: 768px) {
        height: 100vh;
    }
`;

const Slider = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transform-style: preserve-3d;
    transition: transform 0.5s ease-in-out;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const Slide = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.7s ease-in-out;
    transform: translateY(-${(props) => props.transform}%);
    background: ${(props) => props.background || '#ffffff'};
    display: ${(props) => props.footerSlide && 'flex'};
    flex-direction: ${(props) => props.footerSlide && 'column'};
    justify-content: ${(props) => props.footerSlide && 'center'};
    @media (max-width: 768px) {
        height: 100vh;
    }
`;
const VideoContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2;
    @media (max-width: 1024px) {
        height: 100vh;
    }
`;
const ImageContainer = styled.div`
    position: absolute;
    width: max-content;
    height: max-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    img {
        max-width: 20vw;
        object-fit: cover;
    }
    @media (max-width: 768px) {
        img {
            max-width: 50vw;
        }
    }
`;
const MuteWrapper = styled.div`
    position: absolute;
    bottom: 20px;
    left: 15px;
    width: 25px;
    min-width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    svg {
        width: 18px;
    }
    @media (max-width: 768px) {
        bottom: 100px;
    }
`;
const MuteContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
`;
const SlideImage = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.5s ease-in-out;
    @media (max-width: 768px) {
        background-image: url(${(props) => props.mobileSrc});
    }
`;
